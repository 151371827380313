import { create } from 'zustand';

type ModalState = {
  heading?: string;
  error?: boolean;
  content?: JSX.Element | string;
  isOpen?: boolean;
  disableBackgroundClosable?: boolean;
};

type UseModalStore = {
  modalState: ModalState;
  openModal: (modalData: ModalState) => void;
  closeModal: () => void;
};

const useModalStore = create<UseModalStore>((set) => ({
  modalState: {
    isOpen: false,
    heading: '',
    content: '',
    disableBackgroundClosable: true
  },
  openModal: (modalData) => {
    const { heading, content, disableBackgroundClosable = true } = modalData;
    set({
      modalState: {
        heading,
        content,
        disableBackgroundClosable,
        isOpen: true
      }
    });
  },
  closeModal: () => {
    set({
      modalState: { isOpen: false }
    });
  }
}));

export default useModalStore;
